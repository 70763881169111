<template>
  <div class="home">
    <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>Videos</h1>
      </div>
    </div>
    <div class="section bg-gray-4">
      <div class="container">
        <div class="w-layout-grid grid-halves card-grid-halves">
            <VideoPost v-for="video in videos" :key="video.title" :video="video" :editable=false />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from "@/components/Navigation.vue";
import ContentService from "@/services/contentService.js";
import VideoPost from "@/components/VideoPost.vue";
export default {
  name: "home",
  components: {
    Navigation,
    VideoPost
  },
  data() {
    return {
      videos: []
    };
  },
  created() {
    var vm = this;
    ContentService.getContent("video")
      .then(response => {
        vm.videos = response.data.data.video.reverse();
        console.log(vm.videos);
      })
      .catch(error => {
        console.log("There was an error getting videos", error);
      });
  }
};
</script>
